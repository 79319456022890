<template>
    <admin-dashboard-layout :loading="loading">
        <v-container>
            <v-form>
                <v-card class="mx-15 px-15">
                    <v-card-title>
                        <v-row>
                            <v-col v-text="socialGraphic.name"/>
                            <v-col class="text-right">
                                <v-btn text :to="{name: 'admin.social-graphics.index'}">return</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-form>
                            <v-select
                                v-model="socialGraphic.language"
                                :items="languages"
                                item-text="label"
                                item-value="code"
                                outlined
                                dense
                                label="Language">
                                <template slot="selection" slot-scope="data">
                                    {{ $t('languages.' + data.item.code) }}
                                </template>
                                <template slot="item" slot-scope="data">
                                    {{ $t('languages.' + data.item.code) }}
                                </template>
                            </v-select>
                            <v-select
                                v-model="socialGraphic.content_type"
                                :items="content_types"
                                item-text="label"
                                item-value="value"
                                outlined
                                dense
                                label="Content Type">
                                <template slot="selection" slot-scope="data">
                                    {{ $t('content_types.' + data.item.value) }}
                                </template>
                                <template slot="item" slot-scope="data">
                                    {{ $t('content_types.' + data.item.value) }}
                                </template>
                            </v-select>
                            <v-text-field outlined dense label="Name"  v-model="socialGraphic.name"/>
                            <v-row>
                                <v-col md="4" sm="12">
                                    <h2>Feed</h2>
                                    <div v-for="(file, feedIdx) in socialGraphic.files" :key="feedIdx + 'feed'" class="mx-2 my-2">
                                        <template v-if="file.type === 'feed'">
                                            <v-img width="100" :src="file.url"/>
                                            <v-btn @click="removeFile(feedIdx)" v-text="'Remove'" color="red"
                                                   class="mt-1"/>
                                        </template>
                                    </div>
                                    <vue-dropzone
                                        id="dropzone"
                                        ref="feedDropzone"
                                        :options="dropzoneOptions"
                                        @vdropzone-success="handlePushFeed"/>

                                    <h2>Story</h2>
                                    <div v-for="(file, feedIdx) in socialGraphic.files" :key="feedIdx + 'story'"
                                         class="mx-2 my-2">
                                        <template v-if="file.type === 'story'">
                                            <v-img width="100" :src="file.url"/>
                                            <v-btn @click="removeFile(feedIdx)" v-text="'Remove'" color="red" class="mt-1"/>
                                        </template>
                                    </div>
                                    <vue-dropzone
                                        id="storeDropzone"
                                        ref="storeDropzone"
                                        :options="dropzoneOptions"
                                        @vdropzone-success="handlePushStory"/>

                                </v-col>
                                <v-col md="8" sm="12">
                                    <v-textarea outlined v-model="socialGraphic.description" label="Description"/>
                                    <v-textarea outlined v-model="socialGraphic.notes" label="Notes"/>
                                    <v-text-field v-model="socialGraphic.rank" outlined dense label="Rank"/>
                                    <v-select
                                        v-model="socialGraphic.tags"
                                        outlined
                                        dense
                                        label="Tags"
                                        item-text="name"
                                        item-value="id"
                                        :multiple="true"
                                        :items="tags"/>
                                    <v-select
                                        v-model="socialGraphic.industry_id"
                                        outlined
                                        dense
                                        label="Industries"
                                        item-text="name"
                                        item-value="id"
                                        :items="industries"/>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="text-center">
                                    <v-btn @click="handleSave">  Save </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-form>
        </v-container>
    </admin-dashboard-layout>
</template>

<script>
    import AdminDashboardLayout from "@/layouts/AdminDashboardLayout";
    import vue2Dropzone from "vue2-dropzone";
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'
    import Tag from "@/models/Tag";
    import Industry from "@/models/Industry";
    import SocialGraphic from "@/models/SocialGraphic";
    import languages from "@/configs/languages";
    import content_types from "@/configs/content_types";
    import {mapMutations} from "vuex";

    export default {
        name: "Form",
        components: {AdminDashboardLayout, vueDropzone: vue2Dropzone},
        computed: {
            infographicAvatar() {
                if (! this.socialGraphic.files || ! this.socialGraphic.files.length) {
                    return ''
                }
                return this.socialGraphic.files[0].url
            }
        },
        data: function () {
            return {
                languages,
                content_types,
                loading: true,
                carousel: 0,
                socialGraphic: {  files: []},
                dropzoneOptions: {
                    url: '/api/upload',
                    maxFilesize: 10,
                    maxFiles: 99,
                    params: {'directory': 'social-graphics'},
                    headers: {
                        Authorization: null
                    },
                    previewTemplate:  `
                    <div class="dz-preview dz-file-preview" style="min-width: 120px">
                        <div class="dz-details">
                            <div class="dz-filename"><span data-dz-name></span></div>
                            <div class="dz-size" data-dz-size></div>
                        </div>
                        <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                    </div>
                `
                },
                tags: [],
                industries: []
            }
        },
        async mounted() {
            const socialGraphicId = this.$route.params.id

            await this.getIndustries();
            this.tags = await Tag.get();

            if (socialGraphicId) {
                this.socialGraphic = await SocialGraphic.include(['tags']).find(socialGraphicId)
            } else {
                let nextRank = await window.axios.get('/api/helpers/next-rank/social_graphics');
                this.socialGraphic.rank = nextRank.data
                this.$forceUpdate()
            }


            const token = await localStorage.getItem('auth_token_default')
            this.dropzoneOptions.headers.Authorization = "Bearer "+token
            this.loading = false;
        },
        methods: {
            async handleSave() {
                if (this.$_.isArray(this.socialGraphic.tags)) {
                    this.socialGraphic.tags = this.socialGraphic.tags.map((tag) => this.$_.isObject(tag) ? tag.id : tag);
                }

                const socialGraphic = new SocialGraphic(this.socialGraphic)
                this.socialGraphic = await socialGraphic.save()
                this.showSnackBar({color: 'success', timeout: 3000, text: 'Saved successfully'})
                if (this.socialGraphic.id && ! this.$route.params.id) {
                    await this.$router.push({name: 'admin.social-graphic.show', params: {id: this.socialGraphic.id}})
                }
            },
            async getIndustries() {
                this.industries = await Industry.get();

                if (!this.socialGraphic.industry_id) {
                    this.socialGraphic.industry_id = this.industries[0].id
                }
            },
            handlePushFeed(file, res) {
                this.socialGraphic.files.push({type: 'feed', url: res.url})
            },
            handlePushStory(file, res) {
                this.socialGraphic.files.push({type: 'story', url: res.url})
            },
            removeFile(index) {
                this.socialGraphic.files.splice(index,1);
            },
            ...mapMutations(['showSnackBar']),
        }
    }
</script>
