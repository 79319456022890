export default [
    {
        label: 'Quotes',
        value: 'quotes',
    },
    {
        label: 'Charts & Graphs',
        value: 'charts_and_graphs',
    },
    {
        label: 'Lists',
        value: 'lists',
    },
]
