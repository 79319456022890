export default [
    {
        code: 'en',
        flag: 'us',
        label: 'English',
    },
    {
        code: 'zh',
        flag: 'cn',
        label: '中文',
    },
    {
        code: 'ru',
        flag: 'ru',
        label: 'Russian',
    },
]
